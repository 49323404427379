import React, { useRef } from 'react';
import { useEffect } from 'react';
import { GetMeterBillMonth, confirmPrint } from './../../services/billService';
import ReactToPrint from 'react-to-print';
// import ReactBarcode from 'react-barcode'
import ReactQRCode from 'qrcode.react'
import { useState } from 'react';
import moment from 'moment'
import querystring from 'query-string';

const fontSize = 35


class PrintArea extends React.Component  {

    numberWithCommas = (x)=> {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        const moneyColumnWidth = 200
        const QRSize = 300
        const { bill } = this.props;

        const { companyname, companyaddress, label3 } = bill

        const {METER_ID, ref2, invoiceid, duedate } = bill
        const { Meter_Type, electric_rate_type, Date_meter_Record, month_meter } = bill
        const {name, cus_address} = bill

        const { label15, meter_current, FULL_METER, amount_unit } = bill
        const { label19, label21, label34, label35, label23 } = bill
        const { base, ftprice } = bill
        const { meter_multiply='', ftval='' } = bill
        const {Total, VAT, GrandTotal} = bill

        const { label27: news, label28, label36 } = bill
        const { label30:banks=[], label31='', label37='', label38='' } = bill

        const {label40='', label41=''} = bill
        const {  bfunit=0, total_unit=0 } = bill
        const { label42='', discount=0 } = bill

        const { Barcode } = bill

    return ( 
        <div style={{width: 1250, fontSize: fontSize, margin: 0, padding: 0}}>
            <div className="border border-secondary text-left p-2">
                <div className="row justify-content-end">
                    <div className="col-auto mr-auto">
                    {/* <img src={imageHeader} style={{height: 50}} alt="QR" /> */}
                    </div>
                    <div className="col-auto"><strong>{label3}</strong> (สำหรับลูกค้า)(ไม่ใช่ใบเสร็จรับเงิน)</div>
                </div>
                <div className="row justify-content-start">
                    <div className="col-auto"><strong>{companyname}<br />
                    {companyaddress}</strong>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-auto"><i></i></div>
                </div>
                <table className="table table-bordered mt-2" style={{fontSize: fontSize}}>
                    <tbody>
                        <tr>
                            <td colSpan="2">หมายเลขมิเตอร์<br /><strong>{METER_ID}</strong></td>
                            <td>เลขอ้างอิง 2<br /><strong>{ref2}</strong></td>
                            <td>เลขอ้างอิง 1<br /><strong>{invoiceid}</strong></td>
                            <td>วันครบกำหนดชำระ<br /><strong>{moment(duedate).format("DD-MM-YYYY")}</strong></td>
                        </tr>
                        <tr>
                        <td>ประเภท<br /><strong>{Meter_Type}</strong></td>
                        <td>แรงดัน<br /><strong>{electric_rate_type}</strong></td>
                        <td>วันที่อ่านหน่วย<br /><strong>{moment(Date_meter_Record).format("DD-MM-YYYY")}</strong></td>
                        <td>เวลาที่อ่านหน่วย<br /><strong>{moment(Date_meter_Record).format("HH:mm")}</strong></td>
                        <td>ประจำเดือน<br /><strong>{month_meter}</strong></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered mt-2" style={{fontSize: fontSize}}>
                    <tbody>
                        <tr>
                            <td>ชื่อ-ที่อยู่ <strong>{name}<br />{cus_address}</strong></td>
                        </tr>
                    </tbody>
                </table>
                <div className="row align-items-center justify-content-end">
                    <div className="col-auto">{label15}</div>
                    <div className="col-auto">
                        <table className="table table-bordered mt-2" style={{fontSize: fontSize}}>
                            <tbody><tr>
                                    <td>{label37}<br /><strong>{meter_current}</strong></td>
                                    <td>{label38}<br /><strong>{FULL_METER}</strong></td>
                                    <td style={{width:moneyColumnWidth}}>หน่วยที่ใช้<br /><strong>{amount_unit}</strong></td>
                                    <td style={{display: (label40==='') ? 'none' : 'table-cell'}}>{label40}<br /><strong>{bfunit}</strong></td>
                                    <td style={{display: (label41==='') ? 'none' : 'table-cell'}}>{label41}<br /><strong>{total_unit}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col text-right">จำนวนเงิน (บาท)</div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-auto mr-auto">
                        <table className="table table-borderless text-left" style={{fontSize: fontSize}}>
                            <tbody>
                                <tr>
                                    <td>{label19} <strong>{meter_multiply}</strong></td>
                                </tr>
                                <tr>
                                    <td>{label21} <strong>{ftval}</strong></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    <div className="col-auto p-0">
                        <table className="table table-borderless text-right" style={{fontSize: fontSize}}>
                            <tbody>
                                <tr>
                                    <td>{label34}</td>
                                </tr>
                                <tr>
                                    <td>{label35}</td>
                                </tr>
                                {bfunit ===0 ? <tr style={{display: (label42==='') ? 'none' : 'table-row'}}><td>{label42}</td></tr> :null}
                                <tr>
                                    <td>{label23}</td>
                                </tr>
                               
                                <tr>
                                    <td>ภาษีมูลค่าเพิ่ม</td>
                                </tr>
                                {bfunit >0 ? <tr style={{display: (label42==='') ? 'none' : 'table-row'}}><td>{label42}</td></tr> :null}
                                <tr>
                                    <td>รวมเงินที่ชำระ</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-auto">
                        <table className="table table-bordered text-right" style={{fontSize: fontSize}}>
                            <tbody>
                                <tr>
                                    <td style={{width:moneyColumnWidth}}><strong>{this.numberWithCommas(base)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>{this.numberWithCommas(ftprice)}</strong></td>
                                </tr>
                                {bfunit ===0 ? <tr style={{display: (label42==='') ? 'none' : 'table-row'}}><td><strong>{this.numberWithCommas(discount)}</strong></td></tr>:null}
                                <tr>
                                    <td><strong>{this.numberWithCommas(Total)}</strong></td>
                                </tr>
                               
                                <tr>
                                    <td><strong>{this.numberWithCommas(VAT)}</strong></td>
                                </tr>
                                {bfunit > 0 ? <tr style={{display: (label42==='') ? 'none' : 'table-row'}}><td><strong>{this.numberWithCommas(discount)}</strong></td></tr>:null}
                                <tr>
                                    <td><strong>{this.numberWithCommas(GrandTotal)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {Array.isArray(news) && news.length>0 && news.map ( item => {
                    return (<p key={item.text}>{item.text}</p>)
                })}
                <hr />
                <div className="row">
                    <div className="col text-left">
                        {label28} 
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        {label36} <strong>{companyname}</strong>
                    </div>
                </div>
                
                <div className="row mt-2 justify-content-between">
                    <div className="col-auto p-0 ml-4">
                        <div className="row mt-2">
                            <div className="col m-0">
                                {Array.isArray(banks) && banks.length>0 && banks.map( bank => {
                                    return(<span key={bank.bankname} className="px-2">&#x25A1; <strong>{bank.bankname}</strong><br /></span>)

                                })}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <strong>{label31}</strong>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                            เลขอ้างอิง 1 REF. 1 : <strong>{invoiceid}</strong>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                            เลขอ้างอิง 2 REF. 2 : <strong>{ref2}</strong>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                            จำนวนเงิน/Amount <strong>{this.numberWithCommas(GrandTotal)}</strong>
                            </div>
                        </div>
                    </div>


                    <div className="col-auto p-0 m-0 mr-4">
                        <ReactQRCode value={Barcode.replace(/ /g, "")} size={QRSize} />
                    </div>
                </div>
            </div>
        </div>
     )
    }
}
 
const MeterBillMonthly = ({match, location}) => {

    useEffect(() => {
        const loadBill = async () => {

            const id = match.params.id
        
            const query = querystring.parse(location.search);
            if(id && query && query.month) {
                setMonth(query.month)
                const { data: result } = await GetMeterBillMonth(id,query.month);

                if(result.status==='success') {
                    if(result.data.bill) {
                        setBill(result.data.bill)
                    }
                }
            }
        };

       loadBill();
    }, [location, match])

    const [bill, setBill] = useState(undefined)
    const [month, setMonth] = useState('')

    const componentRef = useRef();

    const handlePrintSuccess = async() => {
        try {
            await confirmPrint({ARID: bill ? bill.ARID || '' : '', month})
        } catch(err) {}

    }

    return ( 
    <div className="row">
        <div className="col text-left">
            {bill && <div className="d-inline-block">
                <ReactToPrint
                    trigger={() => <button className="btn btn-primary">Print</button>}
                    content={() => componentRef.current}
                    onAfterPrint={handlePrintSuccess}
                    pageStyle='
                        @media print 
                        {
                            @page {
                            size: A4;
                            margin:0mm;
                            padding:0mm;
                            }
                            html, body {
                                width: 210mm;
                                height: 200mm;
                                margin:0mm;
                                padding:0mm;
                                font-size: 11px;
                                background: #FFF;
                                overflow:visible;
                            }
                            body {
                                margin:0mm;
                                padding:0mm;
                            }
                        }
                        
                        '
                />
                <br /><br />
                <PrintArea ref={componentRef} bill={bill} />
            </div>}
        </div>
      
    </div> );
}
 
export default MeterBillMonthly;